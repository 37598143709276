.LandingPage {
  height: 100vh;
  width: 100%;
  background-color: #f4f6fa;
  display: flex;
  flex-direction: column;

  .TopHeader {
    height: 64px;
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background-color: white;
    padding: 0 20px;
    justify-content: space-between;
    .AppLogo {
      img {
        width: 88px;
      }
    }
    .MenuContainer {
      display: flex;
      align-items: center;
      .MenuItem {
        margin-left: 24px;
      }
    }
  }
  .SearchBoxContainer {
    width: 100%;
    flex: 1;
    // height: 340px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .htmlCompare {
    .popup {
      display: none;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 20px;
      background-color: white;
      border: 1px solid #ccc;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    p {
      text-align: left;
    }
  }
}
